import React from "react";
import { Route, Switch } from "react-router-dom";

//pages
import Error404 from "./pages/error404";
import DashboardDetail from "./pages/dashboard/detail";
import DashboardProfile from "./pages/dashboard/profile";
import Pegawai from "./pages/pegawai/pegawai/index";
import PegawaiAdd from "./pages/pegawai/pegawai/add";
import PegawaiEdit from "./pages/pegawai/pegawai/edit";
import PegawaiDelete from "./pages/pegawai/pegawai/delete";
import PegawaiShift from "./pages/pegawai/shift/index";
import PegawaiRealisasi from "./pages/pegawai/realisasi/index";
import ReferensiForm from "./pages/referensi/form/index";
import ReferensiFormUnit from "./pages/referensi/form/unit";
import ReferensiFormDetail from "./pages/referensi/form/detail";
import ReferensiUnit from "./pages/referensi/unit/index";
import ReferensiJabatan from "./pages/referensi/jabatan/index";
import ReferensiStatus from "./pages/referensi/status/index";
import ReferensiSarpras from "./pages/referensi/sarpras/index";
import ReferensiSarprasSatuan from "./pages/referensi/satuan/index";
import ReferensiHoliday from "./pages/referensi/holiday/index";
import ReferensiRuas from "./pages/referensi/ruas/index";
import ReferensiRuasSection from "./pages/referensi/ruas/index_section";
import ReferensiWeather from "./pages/referensi/weather/index";
import ReferensiTraffic from "./pages/referensi/traffic/index";
import ReferensiReport from "./pages/referensi/report/index";
import ReferensiKategoriKegiatan from "./pages/referensi/kategorikegiatan/index";
import ReferensiKategoriPengaduan from "./pages/referensi/kategoripengaduan/index";
// referensi lakalantas
import ReferensiNomorKasus from "./pages/referensi/lakalantas/nomorkasus/index";
import ReferensiAlinemenJalan from "./pages/referensi/lakalantas/alinemenjalan/index";
import ReferensiAngin from "./pages/referensi/lakalantas/angin/index";
import ReferensiDiagnosa from "./pages/referensi/lakalantas/diagnosa/index";
import ReferensiHasilPertolongan from "./pages/referensi/lakalantas/hasilpertolongan/index";
import ReferensiJarakPenglihatan from "./pages/referensi/lakalantas/jarakpenglihatan/index";
import ReferensiJenisKecelakaan from "./pages/referensi/lakalantas/jeniskecelakaan/index";
import ReferensiJenisLajurArah from "./pages/referensi/lakalantas/jenislajurarah/index";
import ReferensiJenisMedian from "./pages/referensi/lakalantas/jenismedian/index";
import ReferensiJenisPertolongan from "./pages/referensi/lakalantas/jenispertolongan/index";
import ReferensiKategoriKecelakaan from "./pages/referensi/lakalantas/kategorikecelakaan/index";
import ReferensiKategoriKorbanPedestrian from "./pages/referensi/lakalantas/kategorikorbanpedestrian/index";
import ReferensiKeadaanKorban from "./pages/referensi/lakalantas/keadaankorban/index";
import ReferensiKewarganegaraan from "./pages/referensi/lakalantas/kewarganegaraan/index";
import ReferensiKondisiPermukaan from "./pages/referensi/lakalantas/kondisipermukaan/index";
import ReferensiLebarJalur from "./pages/referensi/lakalantas/lebarjalur/index";
import ReferensiPekerjaan from "./pages/referensi/lakalantas/pekerjaan/index";
import ReferensiPekerjaanJalan from "./pages/referensi/lakalantas/pekerjaanjalan/index";
import ReferensiPendidikan from "./pages/referensi/lakalantas/pendidikan/index";
import ReferensiPenyebabKecelakaan from "./pages/referensi/lakalantas/penyebabkecelakaan/index";
import ReferensiPosisiPenumpang from "./pages/referensi/lakalantas/posisipenumpang/index";
import ReferensiPosisiTabrakan from "./pages/referensi/lakalantas/posisitabrakan/index";
import ReferensiRambuBatasKecepatan from "./pages/referensi/lakalantas/rambubataskecepatan/index";
import ReferensiTabrakLari from "./pages/referensi/lakalantas/tabraklari/index";
import ReferensiTempatKorbanPedestrian from "./pages/referensi/lakalantas/tempatkorbanpedestrian/index";
import ReferensiTempatLuka from "./pages/referensi/lakalantas/tempatluka/index";
import ReferensiTipeKorban from "./pages/referensi/lakalantas/tipekorban/index";
import ReferensiSumberInformasi from "./pages/referensi/lakalantas/sumberinformasi/index";
// base
import SettingGroups from "./pages/setting/groups/index";
import SettingPortal from "./pages/setting/portal/index";
import SettingMenu from "./pages/setting/menu/index";
import SettingMenuDetail from "./pages/setting/menu/detail";
import SettingRole from "./pages/setting/role/index";
import SettingRoleDetail from "./pages/setting/role/detail";
import SettingUser from "./pages/setting/user/index";
import SettingUserAdd from "./pages/setting/user/add";
import SettingUserEdit from "./pages/setting/user/edit";
import SettingUserDelete from "./pages/setting/user/delete";
import SettingLang from "./pages/setting/lang/index";
// pik
import PikShift from "./pages/pik/shift/index";
import PikComplaint from "./pages/pik/complaint/index";
import PikForm from "./pages/pik/form/index";
import PikPresensi from "./pages/pik/presensi/index";
import PikPjr from "./pages/pik/pjr/index";
import PikDailyReport from "./pages/pik/dailyreport/index";
import PikSendReport from "./pages/pik/send/index";
import PikReceiveReport from "./pages/pik/receive/index";
// spv
import SpvApproval from "./pages/spv/approval/index";
import SpvApprovalDetail from "./pages/spv/approval/detail";
// report
import ReportLakalantas from "./pages/report/lakalantas/index";
import ReportAtributKepalaShift from "./pages/report/atribut/kepala_shift";
import ReportAtributPatroli from "./pages/report/atribut/patroli";
import ReportAtributRescue from "./pages/report/atribut/rescue";
import ReportAtributAmbulance from "./pages/report/atribut/ambulance";
// import ReportKepalaShift from "./pages/report/kepalashift/index";


import ReportSarprasOdometerKepalaShift from "./pages/report/sarpras/odo_kepala_shift";
import ReportSarprasKepalaShift from "./pages/report/sarpras/sarpras_kepala_shift";
import ReportSarprasPatroli from "./pages/report/sarpras/sarpras_patroli";
import ReportSarprasRescue from "./pages/report/sarpras/sarpras_rescue";
import ReportSarprasAmbulance from "./pages/report/sarpras/sarpras_ambulance";

import ReportPerlengkapanRescue from "./pages/report/sarpras/perlengkapan_rescue";
import ReportPerlengkapanAmbulance from "./pages/report/sarpras/perlengkapan_ambulance";

import ReportSarprasOdometerPatroli from "./pages/report/sarpras/odo_patroli";
import ReportSarprasOdometerRescue from "./pages/report/sarpras/odo_rescue";
import ReportSarprasOdometerAmbulance from "./pages/report/sarpras/odo_ambulance";



import ReportKtigaKepalaShift from "./pages/report/ktiga/ktiga_kepala_shift";
import ReportKtigaPatroli from "./pages/report/ktiga/ktiga_patroli";
import ReportKtigaRescue from "./pages/report/ktiga/ktiga_rescue";
import ReportKtigaAmbulance from "./pages/report/ktiga/ktiga_ambulance";
import ReportKtigaPik from "./pages/report/ktiga/ktiga_pik";



import ReportHasilTugasKepalaShift from "./pages/report/hasil_tugas/hasil_tugas_kepala_shift";
import ReportHasilTugasPatroli from "./pages/report/hasil_tugas/hasil_tugas_patroli";
import ReportHasilTugasRescue from "./pages/report/hasil_tugas/hasil_tugas_rescue";
import ReportHasilTugasAmbulance from "./pages/report/hasil_tugas/hasil_tugas_ambulance";
import ReportHasilTugasPik from "./pages/report/hasil_tugas/hasil_tugas_pik";


const mapping = {
    DashboardDetail: DashboardDetail,
    DashboardProfile: DashboardProfile,
    Pegawai: Pegawai,
    PegawaiAdd: PegawaiAdd,
    PegawaiEdit: PegawaiEdit,
    PegawaiDelete: PegawaiDelete,
    PegawaiShift: PegawaiShift,
    PegawaiRealisasi: PegawaiRealisasi,
    //ref
    ReferensiForm: ReferensiForm,
    ReferensiFormUnit: ReferensiFormUnit,
    ReferensiFormDetail: ReferensiFormDetail,
    ReferensiUnit: ReferensiUnit,
    ReferensiJabatan: ReferensiJabatan,
    ReferensiStatus: ReferensiStatus,
    ReferensiSarpras: ReferensiSarpras,
    ReferensiSarprasSatuan: ReferensiSarprasSatuan,
    ReferensiHoliday: ReferensiHoliday,
    ReferensiRuas: ReferensiRuas,
    ReferensiRuasSection: ReferensiRuasSection,
    ReferensiWeather: ReferensiWeather,
    ReferensiTraffic: ReferensiTraffic,
    ReferensiReport: ReferensiReport,
    ReferensiKategoriKegiatan: ReferensiKategoriKegiatan,
    ReferensiKategoriPengaduan: ReferensiKategoriPengaduan,
    // referensi lakalantas
    ReferensiNomorKasus: ReferensiNomorKasus,
    ReferensiAlinemenJalan: ReferensiAlinemenJalan,
    ReferensiAngin: ReferensiAngin,
    ReferensiDiagnosa: ReferensiDiagnosa,
    ReferensiHasilPertolongan: ReferensiHasilPertolongan,
    ReferensiJarakPenglihatan: ReferensiJarakPenglihatan,
    ReferensiJenisKecelakaan: ReferensiJenisKecelakaan,
    ReferensiJenisLajurArah: ReferensiJenisLajurArah,
    ReferensiJenisMedian: ReferensiJenisMedian,
    ReferensiJenisPertolongan: ReferensiJenisPertolongan,
    ReferensiKategoriKecelakaan: ReferensiKategoriKecelakaan,
    ReferensiKategoriKorbanPedestrian: ReferensiKategoriKorbanPedestrian,
    ReferensiKeadaanKorban: ReferensiKeadaanKorban,
    ReferensiKewarganegaraan: ReferensiKewarganegaraan,
    ReferensiKondisiPermukaan: ReferensiKondisiPermukaan,
    ReferensiLebarJalur: ReferensiLebarJalur,
    ReferensiPekerjaan: ReferensiPekerjaan,
    ReferensiPekerjaanJalan: ReferensiPekerjaanJalan,
    ReferensiPendidikan: ReferensiPendidikan,
    ReferensiPenyebabKecelakaan: ReferensiPenyebabKecelakaan,
    ReferensiPosisiPenumpang: ReferensiPosisiPenumpang,
    ReferensiPosisiTabrakan: ReferensiPosisiTabrakan,
    ReferensiRambuBatasKecepatan: ReferensiRambuBatasKecepatan,
    ReferensiTabrakLari: ReferensiTabrakLari,
    ReferensiTempatKorbanPedestrian: ReferensiTempatKorbanPedestrian,
    ReferensiTempatLuka: ReferensiTempatLuka,
    ReferensiTipeKorban: ReferensiTipeKorban,
    ReferensiSumberInformasi: ReferensiSumberInformasi,
    // core
    SettingGroups: SettingGroups,
    SettingPortal: SettingPortal,
    SettingMenu: SettingMenu,
    SettingMenuDetail: SettingMenuDetail,
    SettingRole: SettingRole,
    SettingRoleDetail: SettingRoleDetail,
    SettingUser: SettingUser,
    SettingUserAdd: SettingUserAdd,
    SettingUserEdit: SettingUserEdit,
    SettingUserDelete: SettingUserDelete,
    SettingLang: SettingLang,
    // pik
    PikShift: PikShift,
    PikComplaint: PikComplaint,
    PikForm: PikForm,
    PikPresensi: PikPresensi,
    PikPjr: PikPjr,
    PikDailyReport: PikDailyReport,
    PikSendReport: PikSendReport,
    PikReceiveReport: PikReceiveReport,
    // spv
    SpvApproval: SpvApproval,
    SpvApprovalDetail: SpvApprovalDetail,
    // report
    ReportLakalantas: ReportLakalantas,
    ReportAtributKepalaShift: ReportAtributKepalaShift,
    ReportAtributPatroli: ReportAtributPatroli,
    ReportAtributRescue: ReportAtributRescue,
    ReportAtributAmbulance: ReportAtributAmbulance,

    ReportSarprasOdometerKepalaShift: ReportSarprasOdometerKepalaShift,

    ReportSarprasOdometerPatroli : ReportSarprasOdometerPatroli,
    ReportSarprasOdometerRescue : ReportSarprasOdometerRescue,
    ReportSarprasOdometerAmbulance : ReportSarprasOdometerAmbulance,

    ReportSarprasKepalaShift: ReportSarprasKepalaShift,
    ReportSarprasPatroli: ReportSarprasPatroli,
    ReportSarprasRescue: ReportSarprasRescue,
    ReportSarprasAmbulance: ReportSarprasAmbulance,

    ReportPerlengkapanRescue: ReportPerlengkapanRescue,
    ReportPerlengkapanAmbulance: ReportPerlengkapanAmbulance,


    ReportKtigaKepalaShift: ReportKtigaKepalaShift,
    ReportKtigaPatroli: ReportKtigaPatroli,
    ReportKtigaRescue: ReportKtigaRescue,
    ReportKtigaAmbulance: ReportKtigaAmbulance,
    ReportKtigaPik: ReportKtigaPik,

    ReportHasilTugasKepalaShift: ReportHasilTugasKepalaShift,
    ReportHasilTugasPatroli: ReportHasilTugasPatroli,
    ReportHasilTugasRescue: ReportHasilTugasRescue,
    ReportHasilTugasAmbulance: ReportHasilTugasAmbulance,
    ReportHasilTugasPik: ReportHasilTugasPik,

};

const Router = (json) => (
    <Switch>
        {json.map((item, index) => {
           
            const Elem = mapping[item.react_component] || Error404;
            //return
            return (
                <Route
                    key={`router__${index}`}
                    exact={Boolean(item.react_exact)}
                    path={item.nav_url}
                    component={(props) => {
                        // console.log(item.nav_url.split("/"));
                        // judul page
                        document.title = item.react_page_title;
                        // breadcrumb
                        let breadcrumb = [];
                        try {
                            breadcrumb = JSON.parse(item.react_breadcrumb);
                        } catch (error) {}
                        //return
                        return (
                            <Elem
                                {...props}
                                breadcrumb={breadcrumb}
                                pagetitle={item.nav_title}
                                pagesubtitle={item.nav_desc}
                            />
                        );
                    }}
                />
            );
        })}
        <Route
            component={(props) => {
                return <Error404 {...props} />;
            }}
        />
    </Switch>
);

export default Router;
