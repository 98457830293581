import React, { useState, Component } from "react";
import classNames from "classnames";
import moment from "moment";

// komponen
import { Content, Empty, Loading, Button } from "../../components/AdminLTE";
import GetPrivate from "../../utils/Connection";
import { NavLink } from "react-router-dom";

// style
const styles = {
    chat: {
        height: "100%"
    }
};

const Tile = props => {
    const [state, setState] = useState(0);
    // panggil get api
    GetPrivate(this, props.url)
        .then(result => {
            setState(result.data);
        })
        .catch(error => {});
    // return
    return (
        <div className={classNames("small-box", props.background)}>
            <div className="inner">
                <h3 className={props.titleStyle}>{state}</h3>
                <p>{props.title}</p>
            </div>
            <div className="icon">
                <i className={props.icon}></i>
            </div>
            <NavLink to={props.linkTo} className="small-box-footer">
                Informasi Detail <i className="fa fa-arrow-circle-right"></i>
            </NavLink>
        </div>
    );
};

class ActivityReport extends Component {
    constructor(props) {
        super(props);
        // state
        this.state = {
            loading: true,
            list: []
        };
        // bind
        this.loadData = this.loadData.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    componentWillUnmount() {
        clearTimeout(this.autoreload);
    }
    // laodData
    loadData() {
        this.setState({ loading: true }, function() {
            // panggil get api
            GetPrivate(this, this.props.url)
                .then(result => {
                    this.setState(
                        {
                            loading: false,
                            list: result.data
                        },
                        function() {
                            clearTimeout(this.autoreload);
                            this.autoreload = setTimeout(this.loadData, 60000);
                        }
                    );
                })
                .catch(error => {});
        });
    }
    //handleClick
    handleClick() {
        this.loadData();
    }
    render() {
        // return
        return (
            <div className="box box-primary direct-chat direct-chat-primary">
                <div className="box-header">
                    <h3 className="box-title">
                        <i className="fa fa-user mr-1"></i>
                        {this.props.title}
                    </h3>
                    <div className="box-tools pull-right">
                        <Button
                            className="btn btn-box-tool"
                            onClick={() => this.handleClick()}
                            loading={this.props.loading}
                        >
                            <i className="fa fa-refresh"></i>
                        </Button>
                    </div>
                </div>
                <div className="box-body no-padding">
                    <div className="scrollbar">
                        <div className="overflow">
                            <div className="area box-body chat">
                                <div
                                    className="direct-chat-messages"
                                    style={styles.chat}
                                >
                                    {this.state.loading ? (
                                        <Loading />
                                    ) : this.state.list.length ? (
                                        this.state.list.map((item, index) => (
                                            <ActivityReportBody
                                                key={`${this.props.title}__${index}`}
                                                detail_shift={item.detail_shift}
                                                detail_created={
                                                    item.detail_created
                                                }
                                                detail_km={item.detail_km}
                                                detail_section={
                                                    item.detail_section
                                                }
                                                detail_activity={
                                                    item.detail_activity
                                                }
                                                detail_follow_up={
                                                    item.detail_follow_up
                                                }
                                                detail_kategori={
                                                    item.detail_kategori
                                                }
                                            />
                                        ))
                                    ) : (
                                        <Empty content="Belum ada laporan" />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const ActivityReportBody = props => {
    return (
        <div className="direct-chat-msg right">
            <div className="direct-chat-info clearfix">
                <span className="direct-chat-name pull-right">
                    {props.detail_shift}
                </span>
                <span className="direct-chat-timestamp pull-left">
                    {props.detail_created}
                </span>
            </div>
            <div className="direct-chat-text">
                <div className="row mb-half">
                    <div className="col-sm-4 text-bold">Lokasi:</div>
                    <div className="col-sm-8">
                        {props.detail_km}
                        {props.detail_section}
                    </div>
                </div>
                <div className="row mb-half">
                    <div className="col-sm-4 text-bold">Kategori:</div>
                    <div className="col-sm-8">
                        {props.detail_kategori || "-"}
                    </div>
                </div>
                <div className="row mb-half">
                    <div className="col-sm-4 text-bold">Kegiatan:</div>
                    <div className="col-sm-8">{props.detail_activity}</div>
                </div>
                <div className="row mb-half">
                    <div className="col-sm-4 text-bold">Keterangan:</div>
                    <div className="col-sm-8">{props.detail_follow_up}</div>
                </div>
            </div>
        </div>
    );
};

const Dashboard = props => {
    // datas
    const today = moment();
    const start_date = moment(today)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    const end_date = moment(today).format("YYYY-MM-DD");
    // return
    return (
        <Content
            title={props.pagetitle}
            subtitle={props.pagesubtitle}
            breadcrumb={props.breadcrumb}
        >
            <div className="row">
                <div className="col-lg-3 col-xs-6">
                    <Tile
                        background="bg-primary"
                        title="Total Kejadian"
                        titleStyle="font-size-115"
                        url={`user/dashboard/total_lakalantas?start=${start_date}&end=${end_date}`}
                        icon="fa fa-pie-chart"
                        linkTo={`/dashboard/report/lakalantas`}
                    />
                </div>

                <div className="col-lg-9 col-xs-12">
                    <div className="row">
                        <div className="col-lg-4 col-xs-12">
                            <Tile
                                background="bg-red"
                                title="Meninggal Dunia"
                                url={`user/dashboard/keadaan_korban?id=4&start=${start_date}&end=${end_date}`}
                                icon="fa fa-hospital-o"
                                linkTo={`/dashboard/report/lakalantas?keadaan_korban=4`}
                            />
                        </div>
                        <div className="col-lg-4 col-xs-12">
                            <Tile
                                background="bg-yellow"
                                title="Luka Berat"
                                url={`user/dashboard/keadaan_korban?id=7&start=${start_date}&end=${end_date}`}
                                icon="fa fa-ambulance"
                                linkTo={`/dashboard/report/lakalantas?keadaan_korban=7`}
                            />
                        </div>
                        <div className="col-lg-4 col-xs-12">
                            <Tile
                                background="bg-aqua"
                                title="Luka Ringan"
                                url={`user/dashboard/keadaan_korban?id=8&start=${start_date}&end=${end_date}`}
                                icon="fa fa-medkit"
                                linkTo={`/dashboard/report/lakalantas?keadaan_korban=8`}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-xs-12">
                            <Tile
                                background="bg-green"
                                title="Mogok"
                                url={`user/dashboard/penyebab_kecelakaan?id=11&start=${start_date}&end=${end_date}`}
                                icon="fa fa-gear"
                                linkTo={`/dashboard/report/lakalantas?penyebab_kecelakaan=11`}
                            />
                        </div>
                        <div className="col-lg-4 col-xs-12">
                            <Tile
                                background="bg-teal"
                                title="Pecah Ban"
                                url={`user/dashboard/penyebab_kecelakaan?id=6&start=${start_date}&end=${end_date}`}
                                icon="fa fa-life-buoy"
                                linkTo={`/dashboard/report/lakalantas?penyebab_kecelakaan=6`}
                            />
                        </div>
                        <div className="col-lg-4 col-xs-12">
                            <Tile
                                background="bg-purple"
                                title="Gangguan Kamtibmas"
                                url={`user/dashboard/penyebab_kecelakaan?id=15&start=${start_date}&end=${end_date}`}
                                icon="fa fa-wrench"
                                linkTo={`/dashboard/report/lakalantas?penyebab_kecelakaan=15`}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <ActivityReport
                        title="Kegiatan Patroli"
                        url={`user/dashboard/activity?unit=5&start=${start_date}&end=${end_date}`}
                    />
                </div>
                <div className="col-md-4">
                    <ActivityReport
                        title="Kegiatan Rescue"
                        url={`user/dashboard/activity?unit=4&start=${start_date}&end=${end_date}`}
                    />
                </div>
                <div className="col-md-4">
                    <ActivityReport
                        title="Kegiatan Ambulance"
                        url={`user/dashboard/activity?unit=6&start=${start_date}&end=${end_date}`}
                    />
                </div>
            </div>
        </Content>
    );
};

export default Dashboard;
