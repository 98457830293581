import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// komponen
import {
    Content,
    ButtonRole,
    Input,
    BoxCollapse
} from "../../../components/AdminLTE";

// utils
import GetPrivate from "../../../utils/Connection";
import Dates from "../../../utils/Dates";


// page
import AtributRescueTable from "./table";
import AtributRescueSearch from "./search";
import AtributRescueModal from "./modal";

class Rescue extends Component {
    constructor(props) {
        super(props);
        // search
        this.search = this.props.location.search;
        // state
        this.state = {
            url: "",
            loading: false,
            modal: false,
            list: [],
            start: Dates.getTodayDB(),
            // start: Dates.get7DaysAgo(),
            end: Dates.getTodayDB(),
            date: Dates.getTodayDB(),
            title:'Laporan Atribut Rescue'

        };
        // bind
        this.toogleShow = this.toogleShow.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this);
    }
    componentDidMount() {
        // loadData
        this.loadData();
        this.loadRef();
    }
    // load data
    loadRef() {
        GetPrivate(this, `referensi/ruas`)
            .then(result => {
                this.setState(
                    {
                        list_ruas: result.data.map(item => ({
                            value: item.ruas_id,
                            label: item.ruas_name
                        })),
                        ruas_id: result.data[0].ruas_id,
                        ruas_name: result.data[0].ruas_name
                    },
                    function() {
                        // loadData
                        this.loadData();
                    }
                );
            })
            .catch(error => {});
    }
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `user/reportatribut/all${this.search}?unit_id=4&ruas=${this.state.ruas_id}&date=${this.state.start}&end=${this.state.end}`)
                .then(result => {
                    this.setState({
                        loading: false,
                        list: result.data
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // handle change
    handleChangeStart(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }
    handleChange(field, value) {
        this.setState({ [field]: value });
        this.loadData();
    }

    handleChangeSelect(item) {
        this.setState(
            { ruas_id: item.value, ruas_name: item.label },
            function() {
                this.loadData();
            }
        );
    }
    // on submit
    onSubmit = event => {
        event.preventDefault();
        // load data
        this.loadData();
    };
    toogleShow(url = "") {
        this.setState({ modal: !this.state.modal, url: url });
    }
    // render
    render() {
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
            <BoxCollapse open={true} title="">
                    <AtributRescueSearch
                        handleChange={this.handleChange}
                        handleChangeStart={this.handleChangeStart}
                        handleChangeSelect={this.handleChangeSelect}
                        date={this.state.date}
                        // end={this.state.end}
                        ruas_id={this.state.ruas_id}
                        ruas_name={this.state.ruas_name}
                        list_ruas={this.state.list_ruas}
                    />
                    </BoxCollapse>
                <div className="box">
                    <div className="box-body">
                        <AtributRescueTable
                            {...this.props}
                            list={this.state.list}
                            loading={this.state.loading}
                            toogleShow={this.toogleShow}
                        />
                    </div>
                </div>
                {this.state.modal && (
                    <AtributRescueModal
                    title={this.state.title}
                        show={this.state.modal}
                        toogleShow={this.toogleShow}
                        url={this.state.url}
                    />
                )}
            </Content>
        );
    }
}

export default Rescue;
