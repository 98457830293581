import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Tabs = props => {
    // hooks
    const [state, setState] = useState(props.default);
    // return
    return (
        <div className="nav-tabs-custom">
            <ul
                className={
                    props.direction == "right"
                        ? "nav nav-tabs pull-right"
                        : "nav nav-tabs"
                }
            >
                {props.list.map(item => (
                    <li
                        key={item.key}
                        className={item.key == state ? "active" : ""}
                    >
                        <NavLink
                            to="#"
                            onClick={event => {
                                event.preventDefault();
                                setState(item.key);
                            }}
                        >
                            {item.title}
                        </NavLink>
                    </li>
                ))}
            </ul>
            <div className="tab-content">
                {props.list.map(item => (
                    <div
                        key={item.key}
                        className={
                            item.key == state ? "tab-pane active" : "tab-pane"
                        }
                    >
                        {item.content}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tabs;
