import React from "react";
import { NavLink } from "react-router-dom";

// komponen
import Profile from "./header_profile";
import Notification from "./header_notification";

// resources
import logo_full from "../../resources/img/logo-full.png";
import logo_small from "../../resources/img/logo-small.png";

const Header = (props) => {
    // return
    return (
        <header className="main-header">
            <NavLink to="/" className="logo">
                <span className="logo-mini">
                    <img src={logo_small} alt="Logo" />
                </span>
                <span className="logo-lg">
                    {/* <img src={logo_full} alt="Logo" /> */}
                    TMS
                </span>
            </NavLink>
            <nav className="navbar navbar-static-top" role="navigation">
                <NavLink
                    to="#"
                    className="sidebar-toggle"
                    onClick={(event) => {
                        event.preventDefault();
                        props.toogleSidebar();
                    }}
                >
                    <span className="sr-only">Toggle navigation</span>
                </NavLink>
                <div className="navbar-custom-menu">
                    <ul className="nav navbar-nav">
                    <a class="sidebar-download active" target="_target" href="https://bit.ly/apk_jmto"><button class="btn btn-box-tool"><i class="fa fa-download"></i></button></a> 
                    </ul>
                </div>
                <div className="navbar-custom-menu">
                    <ul className="nav navbar-nav">
                        <Profile {...props} />
                    </ul>
                </div>
            </nav>
        </header>
    );
};
// export
export default Header;
