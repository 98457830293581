import React from "react";

// komponen
import {
    Table,
    Button,
    Input,
    InputSelectReact
} from "../../../components/AdminLTE";
import Select from "react-select";

// page
import SarprasOptions from "./options_sarpras";
import SatuanOptions from "./options_satuan";

// table
const thead = props => {
    return [
        {
            width: "5%",
            className: "text-center",
            label: (
                <Button
                    type="button"
                    className="btn btn-sm btn-default"
                    loading={props.loading}
                    onClick={props.addRow}
                >
                    <i className="fa fa-plus"></i>
                </Button>
            )
        },
        { width: "40%", className: "text-center", label: "Sarana Prasarana" },
        { width: "10%", className: "text-center", label: "Jumlah" },
        { width: "30%", className: "text-center", label: "Satuan" },
        { width: "15%", className: "text-center", label: "Grup" }
    ];
};

const Tbody = props => {
    return props.list.map((item, index) => (
        <tr key={index}>
            <td className="text-center">
                <Button
                    type="button"
                    className="btn btn-sm btn-default"
                    loading={props.loading}
                    onClick={() =>
                        item.code_id != ""
                            ? props.deleteConfirm(item, index)
                            : props.removeRow(index)
                    }
                >
                    <i className="fa fa-trash"></i>
                </Button>
            </td>
            <td>
                <InputSelectReact
                    defaultValue={{
                        label: item.sarpras_name,
                        value: item.sarpras_id
                    }}
                    options={props.list_sarpras}
                    onChange={item =>
                        props.handleChange(index, "sarpras_id", item.value)
                    }
                />
            </td>
            <td>
                <Input
                    type="number"
                    placeholder="Jumlah"
                    defaultValue={item.form_qty}
                    onChange={ev =>
                        props.handleChange(index, "form_qty", ev.target.value)
                    }
                />
            </td>
            <td>
                <InputSelectReact
                    defaultValue={{
                        label: item.satuan_name,
                        value: item.satuan_id
                    }}
                    options={props.list_satuan}
                    onChange={item =>
                        props.handleChange(index, "satuan_id", item.value)
                    }
                />
            </td>
            <td>
                <Input
                    type="text"
                    placeholder="Grup"
                    defaultValue={item.form_group}
                    onChange={ev =>
                        props.handleChange(index, "form_group", ev.target.value)
                    }
                />
            </td>
        </tr>
    ));
};

const DetailTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Belum ada formulir, silahkan klik tombol + untuk menambahkan form"
            className="table table-bordered table-hover"
            loading={props.loading}
        />
    );
};

export default DetailTable;
