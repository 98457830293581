import React, { Component } from "react";

// utils
import GetPrivate, { PostPrivate } from "../../../utils/Connection";

// komponen
import {
    Content,
    Loading,
    Button,
    Callout,
    Empty,
    InputTextarea
} from "../../../components/AdminLTE";
import {
    SwalWarning,
    SwalSuccess,
    SwalConfirm
} from "../../../components/Alert";

// pages
import ReportTable from "./table_detail";
import ReceiveModal from "./modal_detail";

class PikReceive extends Component {
    constructor(props) {
        super(props);
        // role id
        this.report_id = props.match.params.id;
        // state
        this.state = {
            note: "",
            approval: "",
            list: [],
            modal: false,
            modal_content: "",
            modal_title: "",
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onApproval = this.onApproval.bind(this);
        this.toogleShow = this.toogleShow.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    // handle change
    handleChange(index, label, value) {
        let { list } = this.state;
        list[index][label] = value;
        this.setState({ list: list });
    }
    //loadData
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            GetPrivate(this, `user/finalapproval/detail/${this.report_id}`)
                .then(result => {
                    // set state
                    this.setState({
                        loading: false,
                        list: result.data.list_report
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // kirim
        this.sendProcess();
    };
    // sendProcess
    sendProcess() {
        // cek konfirmasi
        if (!this.checkConfirm()) return;
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                report_id: this.report_id,
                note: this.state.note,
                approval: this.state.approval,
                report: JSON.stringify(that.state.list)
            };
            // paggil post api
            PostPrivate(that, `user/finalapproval/send`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // - redirect
                            that.props.history.replace(
                                `/dashboard/spv/approval`
                            );
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    }
    // checkConfirm
    checkConfirm() {
        let { list } = this.state;
        for (let index = 0; index < list.length; index++) {
            if (list[index]["approval"] == "") {
                // alert
                SwalWarning("Harap konfirmasi laporan sebelum dikirim");
                return false;
            }
        }
        if (this.state.approval == "reject" && !this.state.note) {
            // alert
            SwalWarning("Harap mengisi alasan penolakan");
            return false;
        }
        return true;
    }
    onApproval(approval) {
        SwalConfirm(
            "Peringatan",
            "Lanjutkan approval?",
            () =>
                this.setState({ approval: approval }, function() {
                    this.sendProcess();
                }),
            "Ya, Lanjutkan",
            "-"
        );
    }
    toogleShow(modal_content = "", modal_title = "") {
        this.setState({
            modal: !this.state.modal,
            modal_content: modal_content,
            modal_title: modal_title
        });
    }
    render() {
        const { is_completed, list, note } = this.state;
        return (
            <Content
                title={this.props.pagetitle}
                breadcrumb={this.props.breadcrumb}
            >
                {this.state.loading ? (
                    <Loading />
                ) : this.report_id ? (
                    <div className="row">
                        <div className="col-md-12">
                            <form onSubmit={this.onSubmit}>
                                <ReportContent
                                    {...this.props}
                                    is_completed={is_completed}
                                    list={list}
                                    note={note}
                                    handleChange={this.handleChange}
                                    handleChangeTextarea={ev =>
                                        this.setState({ note: ev.target.value })
                                    }
                                    onApproval={this.onApproval}
                                    toogleShow={this.toogleShow}
                                />
                            </form>
                        </div>
                    </div>
                ) : (
                    <Empty content="Belum ada laporan" />
                )}
                {this.state.modal && (
                    <ReceiveModal
                        show={this.state.modal}
                        modal_content={this.state.modal_content}
                        modal_title={this.state.modal_title}
                        toogleShow={this.toogleShow}
                    />
                )}
            </Content>
        );
    }
}

const ReportContent = props => {
    return (
        <>
            <Callout
                type="info"
                title="Perhatian"
                content="Harap mengecek laporan sebelum melakukan approval"
            />
            <div className="box no-border">
                <div className="box-body">
                    <ReportTable
                        list={props.list}
                        handleChange={props.handleChange}
                        toogleShow={props.toogleShow}
                    />
                </div>
            </div>
            <div className="box no-border">
                <div className="box-header with-border">
                    <h3 className="box-title">Catatan penolakan</h3>
                </div>
                <div className="box-body">
                    <InputTextarea
                        id={"note"}
                        placeholder="Ketikkan alasan penolakan"
                        defaultValue={props.note}
                        onChange={props.handleChangeTextarea}
                        rows={6}
                    />
                </div>
            </div>
            <div className="text-right">
                <Button
                    type="button"
                    className="btn btn-success mr-2"
                    onClick={() => props.onApproval("accept")}
                >
                    <i className="fa fa-check"></i> Setujui Laporan
                </Button>
                <Button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => props.onApproval("reject")}
                >
                    <i className="fa fa-times"></i> Tolak Laporan
                </Button>
            </div>
        </>
    );
};

export default PikReceive;
