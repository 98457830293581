import React, { Component } from "react";
import classNames from "classnames";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import setSession from "../../reducers/session/actions.js";
import setMenu from "../../reducers/menu/actions.js";

// utils
import Session from "../../utils/Session";
import GetPrivate from "../../utils/Connection";

// router
import Router from "../../router";

//komponen
import { Header, Sidebar, Footer, LoadingBig } from "../../components/AdminLTE";
import { Toast } from "../../components/Alert";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        //state
        this.state = {
            sidebar: true,
            loading_sidebar: true,
            loading_logout: false,
            menu: []
        };
        // bind
        this.logoutProcess = this.logoutProcess.bind(this);
    }
    // did mount
    componentDidMount() {
        // get user profile
        this.getProfile();
    }
    // get profile
    getProfile() {
        // panggil get api
        GetPrivate(this, "user/profile")
            .then(result => {
                // store
                this.props.setSession(result.data).then(result =>
                    // get user menu
                    this.getUserMenu()
                );
            })
            .catch(error => {});
    }
    // get user menu
    getUserMenu() {
        this.setState({ loading_sidebar: true }, function() {
            // panggil get api
            GetPrivate(this, "user/menu")
                .then(result => {
                    this.props.setMenu(result.data).then(() =>
                        this.setState({
                            loading_sidebar: false,
                            menu: result.data
                        })
                    );
                })
                .catch(error => {
                    // set loading
                    this.setState({
                        loading_sidebar: false
                    });
                });
        });
    }
    //logoutProcess
    logoutProcess() {
        this.setState({ loading_logout: true }, function() {
            // panggil get api
            GetPrivate(this, "logout")
                .then(result => {
                    // remove session
                    Session.clearSession("session__jmto_admin");
                    Session.clearSession("default_page__jmto_admin");
                    // store
                    this.props.setSession({}).then(() => {
                        // redirect login
                        this.props.history.push("/");
                        // notif
                        Toast("Logout Berhasil");
                    });
                })
                .catch(error => {});
        });
    }
    // render
    render() {
        return (
            <div
                className={classNames(
                    "hold-transition",
                    "skin-blue",
                    "sidebar-mini",
                    { "sidebar-open": !this.state.sidebar },
                    { "sidebar-collapse": !this.state.sidebar }
                )}
            >
                <div className="wrapper">
                    <Header
                        {...this.props}
                        toogleSidebar={() =>
                            this.setState({ sidebar: !this.state.sidebar })
                        }
                        loading={this.state.loading_logout}
                        logoutProcess={this.logoutProcess}
                    />
                    <Sidebar
                        {...this.props}
                        menu={this.state.menu}
                        loading={this.state.loading_sidebar}
                    />
                    <div className="content-wrapper">
                        {this.state.loading_sidebar ? (
                            <LoadingBig />
                        ) : (
                            Router(this.state.menu)
                        )}
                    </div>
                    <Footer />
                </div>
            </div>
        );
    }
}

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        session: state.session,
        menu: state.menu
    };
}
// dispatch / aksi -> props
function mapsDispatchToProps(dispatch) {
    return {
        setSession: params => {
            return new Promise((resolve, reject) => {
                dispatch(setSession(params));
                resolve();
            });
        },
        setMenu: params => {
            return new Promise((resolve, reject) => {
                dispatch(setMenu(params));
                resolve();
            });
        }
    };
}
// hubungkan dengan redux
export default withRouter(
    connect(mapsStateToProps, mapsDispatchToProps)(Dashboard)
);
