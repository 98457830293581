import React from "react";

// komponen
import { Table, Input, InputCheckbox } from "../../../components/AdminLTE";

// table
const thead = props => {
    let list = [
        { width: "10px", className: "text-center", label: "No" },
        { width: "100px", className: "text-center", label: "Judul Report" },
        { width: "15px", className: "text-center", label: "Harus Diisi?" }
    ];
    Object.keys(props.list_unit).map(unit_id => {
        list.push({
            width: "10px",
            className: "text-center",
            label: props.list_unit[unit_id]
        });
    });
    return list;
};

const Tbody = props => {
    return props.list.map((item, index) => {
        let list = [
            <td className="text-center">{index + 1}</td>,
            <td>
                <Input
                    type="text"
                    placeholder="Judul Report"
                    defaultValue={item.report_title}
                    onChange={ev =>
                        props.handleChange(
                            index,
                            "report_title",
                            ev.target.value
                        )
                    }
                    readOnly={item.report_editable == "0"}
                />
            </td>,
            <td className="text-center">
                <InputCheckbox
                    id={`reports_required__${index}`}
                    checked={item.report_required == "1"}
                    onChange={() =>
                        props.handleChange(
                            index,
                            "report_required",
                            item.report_required == "1" ? "0" : "1"
                        )
                    }
                />
            </td>
        ];
        Object.keys(item.list_unit).map(unit_id => {
            list.push(
                <td className="text-center">
                    <InputCheckbox
                        id={`reports__${unit_id}__${index}`}
                        checked={item.list_unit[unit_id]}
                        onChange={() =>
                            props.handleCheck(
                                index,
                                unit_id,
                                !item.list_unit[unit_id]
                            )
                        }
                    />
                </td>
            );
        });
        return <tr key={index}>{list}</tr>;
    });
};

const ReportTable = props => {
    return (
        <Table
            thead={thead(props)}
            tbody={<Tbody {...props} />}
            empty="Kosong"
            className="table table-bordered table-hover"
            loading={props.loading}
        />
    );
};

export default ReportTable;
