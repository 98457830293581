import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch, HashRouter } from "react-router-dom";
import { history } from "./configureStore";

//--pages
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Error404 from "./pages/error404";

const App = ({ store }) => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <HashRouter basename="/">
                <Switch>
                    <Route path="/" component={Login} exact={true} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route component={Error404} />
                </Switch>
            </HashRouter>
        </ConnectedRouter>
    </Provider>
);

App.propTypes = {
    store: PropTypes.object.isRequired
    // store: PropTypes.object
};

export default App;
