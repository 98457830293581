import React from "react";

const Footer = props => {
    return (
      <footer className="main-footer">
        <strong>
          Copyright &copy; 2019{" "}
          <span className="text-primary">TOL Management System</span>.
        </strong>{" "}
        All rights reserved.
      </footer>
    );
};

export default Footer;
