import React from "react";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// default image
import img_default from "../../resources/img/img-default.jpg";

const ProfileUserSidebar = (props) => {
    return (
        <div className="box">
            <div className="box-body text-center mt-1">
                <img
                    src={
                        props.session.pegawai_image
                            ? props.session.pegawai_image
                            : img_default
                    }
                    className="img-circle"
                    alt="Profil"
                    width="100"
                    height="100"
                />
                <h4>{props.session.pegawai_name}</h4>
                <p className="text-muted">
                    {props.session.pegawai_unit
                        ? props.session.pegawai_unit
                        : props.session.pegawai_role}
                </p>
            </div>
        </div>
    );
};

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        session: state.session,
    };
}
// hubungkan dengan redux
export default withRouter(connect(mapsStateToProps, null)(ProfileUserSidebar));
