import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";

// default image
import img_default from "../../resources/img/img-default.jpg";

// komponen
import Button from "../AdminLTE/button";

const Profile = (props) => {
    // hooks
    const [state, setState] = useState(false);
    //open dropdown
    let dropdown = classNames("dropdown", "user", "user-menu", {
        open: state,
    });
    //return
    return (
        <li className={dropdown}>
            <NavLink
                to="#"
                className="dropdown-toggle"
                onClick={(event) => {
                    event.preventDefault();
                    setState(!state);
                }}
            >
                <img
                    src={
                        props.session.pegawai_image
                            ? props.session.pegawai_image
                            : img_default
                    }
                    className="user-image"
                    alt="Profil"
                />
                <span className="hidden-xs">{props.session.pegawai_name}</span>
            </NavLink>
            <ul className="dropdown-menu">
                <li className="user-header">
                    <img
                        src={
                            props.session.pegawai_image
                                ? props.session.pegawai_image
                                : img_default
                        }
                        className="img-circle"
                        alt="Profil"
                    />

                    <p>
                        {props.session.pegawai_name}
                        <small>
                            {props.session.pegawai_unit
                                ? props.session.pegawai_unit
                                : props.session.pegawai_role}
                        </small>
                    </p>
                </li>
                <li className="user-footer">
                    <div className="pull-left">
                        <NavLink
                            to="/dashboard/profile"
                            className="btn btn-default btn-flat"
                            onClick={() => setState(false)}
                        >
                            Profil
                        </NavLink>
                    </div>
                    <div className="pull-right">
                        <Button
                            type="button"
                            className="btn btn-default btn-flat"
                            onClick={() => props.logoutProcess()}
                            loading={props.loading}
                        >
                            Log out
                        </Button>
                    </div>
                </li>
            </ul>
        </li>
    );
};

export default Profile;
