import React, { Component } from "react";
import { Modal } from "../../../components/AdminLTE";

const LakalantasModal = props => {
    return (
        <Modal
            title="Laporan Kecelakaan Lalu Lintas"
            show={props.show}
            onDismiss={() => props.toogleShow()}
            size="large"
            content={<iframe src={props.url} className="iframe"></iframe>}
            bodyStyle="p-0"
        />
    );
};

export default LakalantasModal;
