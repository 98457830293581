import React from "react";
import moment from "moment";
// import idLocale from "moment/locale/id";

// moment.locale("id", idLocale);

// komponen
import {
    TableBootstrap,
    ButtonRole,
    Button,
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "5%",
        center: true,
    },
    {
        name: "Unit",
        selector: "code_name",
        sortable: true,
        width: "20%",
    },
      {
        name: "Nama Petugas",
        selector: "pegawai_name",
        sortable: true,
        width: "20%",
    },
    {
        name: "Km Awal",
        selector: "odometer_km_start",
        sortable: true,
        width: "30%",
    },
    {
        name: "Km Akhir",
        selector: "odometer_km_finish",
        sortable: true,
        width: "20%",
        center: true,
    },
    {
        name: "Detail",
        selector: "detail",
        width: "10%",
        center: true,
    },
];

const data = (props) => {
    console.log('====================================');
    console.log(props);
    console.log('====================================');
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["km"] = `${item.detail_km}${item.detail_km_to}${item.lane_name}`;
        // item["date"] = moment(`${item.detail_date} ${item.detail_time}`).format(
        //     "DD MMMM YYYY HH:mm"
        // );
        item["date"] = `${item.detail_date} ${item.detail_time}`;
        item["detail"] = (
            <Button
                className="btn btn-default"
                onClick={() => props.toogleShow(item.webview)}
            >
                Detail
            </Button>
        );
        return item;
    });
};

const KepalaShiftTable = (props) => {
    return (
        <TableBootstrap
            keyField={"lakalantas"}
            empty="Belum ada laporan"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default KepalaShiftTable;
