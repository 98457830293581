import React, { Component } from "react";

// komponen
import { SwalSuccess } from "../../../components/Alert";
import { Modal, Button, Input } from "../../../components/AdminLTE";

// utils
import { PostPrivate } from "../../../utils/Connection";

class UnitModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // mode
        this.title =
            props.mode == "edit"
                ? "Ubah Referensi Unit"
                : "Tambah Referensi Unit";
        // state
        this.state = {
            // field input
            unit_id: props.item ? props.item.unit_id : "",
            unit_name: props.item ? props.item.unit_name : "",
            // error field
            error_field: {
                unit_name: null
            },
            // loading
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                unit_id: that.state.unit_id,
                unit_name: that.state.unit_name
            };
            // paggil post api
            PostPrivate(that, `referensi/unit/${that.props.mode}`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    render() {
        // return
        return (
            <Modal
                form={true}
                title={this.title}
                content={
                    <>
                        <div className="form-group row">
                            <label
                                htmlFor={"unit_name"}
                                className="col-sm-3 control-label mt-1"
                            >
                                Nama Unit
                            </label>
                            <div className="col-sm-5">
                                <Input
                                    id={"unit_name"}
                                    type="text"
                                    placeholder="Nama Unit"
                                    has_error={this.state.error_field.unit_name}
                                    defaultValue={this.state.unit_name}
                                    onChange={ev =>
                                        this.handleChange(
                                            "unit_name",
                                            ev.target.value
                                        )
                                    }
                                    error_field={
                                        this.state.error_field.unit_name
                                    }
                                />
                            </div>
                        </div>
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={this.props.toogleShow}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default UnitModal;
