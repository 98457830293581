import React from "react";

const Button = props => {
    return (
        <button
            type={props.type}
            className={props.className}
            disabled={props.loading || props.disabled}
            onClick={props.onClick}
        >
            {props.loading ? (
                <>
                    <i className="fa fa-spinner fa-pulse"></i> Loading ...
                </>
            ) : (
                props.children
            )}
        </button>
    );
};

export default Button;
