import React, { useState } from "react";

// default image
import img_default from "../../resources/img/img-default.jpg";

// komponen
import Loading from "../AdminLTE/loading";
import SidebarMenu from "./sidebarmenu";

const formatMenu = (list, parent = 0) => {
    let result = [];
    list.forEach((item) => {
        if (item.parent_id == parent && item.display_st == 1) {
            let list_child = formatMenu(list, item.nav_id);
            if (list_child.length) {
                item.list_child = list_child;
            }
            result.push(item);
        }
    });
    return result;
};

const SidebarMenuList = (props) => {
    // hooks
    const [state, setState] = useState("");
    return props.list.map((item) => {
        return (
            <SidebarMenu
                {...props}
                id={item.nav_id}
                state={state}
                setState={setState}
                key={item.nav_id}
                icon={item.nav_icon}
                title={item.nav_title}
                url={item.nav_url}
                list_children={
                    item.list_child && (
                        <SidebarMenuList {...props} list={item.list_child} />
                    )
                }
            />
        );
    });
};

const Sidebar = (props) => {
    // hooks
    const [isReload, setIsReload] = useState(true);
    // return
    return (
        <aside className="main-sidebar">
            <section className="sidebar">
                <div className="user-panel">
                    <div className="pull-left image">
                        <img
                            src={
                                props.session.pegawai_image
                                    ? props.session.pegawai_image
                                    : img_default
                            }
                            className="img-circle"
                            alt="Profil"
                        />
                    </div>
                    <div className="pull-left info">
                        <p>{props.session.pegawai_name} </p>
                        <small>
                            {props.session.pegawai_unit
                                ? props.session.pegawai_unit
                                : props.session.pegawai_role}
                        </small>
                    </div>
                </div>
                <ul className="sidebar-menu">
                    <li className="header">NAVIGATION</li>
                    {props.loading ? (
                        <Loading />
                    ) : (
                        <SidebarMenuList
                            {...props}
                            isReload={isReload}
                            setIsReload={setIsReload}
                            list={formatMenu(props.menu)}
                        />
                    )}
                </ul>
            </section>
        </aside>
    );
};

export default Sidebar;
