import React from "react";
import classNames from "classnames";

const Modal = props => {
    // modals
    let shows = props.show ? "in" : "";
    const type = "modal-" + (props.type ? props.type : "default");
    let modals = classNames("modal", type, "fade", shows);
    let modalBody = classNames("modal-body", props.bodyStyle);
    let sizes = "";
    switch (props.size) {
        case "large":
            sizes = "modal-lg";
            break;
        case "small":
            sizes = "modal-sm";
            break;
    }
    let modalsize = classNames("modal-dialog", sizes);
    // return
    return (
        <div
            className={modals}
            tabIndex="-1"
            style={{
                display: props.show ? "block" : "none",
                overflowY: "auto"
            }}
        >
            <div className={modalsize} role="document">
                {props.form ? (
                    <form onSubmit={props.onSubmit}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    onClick={props.onDismiss}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <h4 className="modal-title">{props.title}</h4>
                            </div>
                            <div className={modalBody}>{props.content}</div>
                            {props.footer && (
                                <div className="modal-footer">
                                    {props.footer}
                                </div>
                            )}
                        </div>
                    </form>
                ) : (
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                onClick={props.onDismiss}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">{props.title}</h4>
                        </div>
                        <div className={modalBody}>{props.content}</div>
                        {props.footer && (
                            <div className="modal-footer">{props.footer}</div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Modal;
