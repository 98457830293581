import React from "react";
import { NavLink } from "react-router-dom";

// komponen
import { TableBootstrap, ButtonRole } from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "10%",
        center: true,
    },
    {
        name: "Nama Lengkap",
        selector: "user_alias",
        sortable: true,
        width: "65%",
    },
    {
        name: "Status",
        selector: "user_st_custom",
        sortable: true,
        width: "10%",
        center: true,
    },
    {
        name: "",
        selector: "action",
        width: "15%",
        center: true,
    },
];

const data = (props) => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["user_st_custom"] =
            item.user_st == "1" ? (
                <span className="label label-success">Aktif</span>
            ) : (
                <span className="label label-warning">Suspend</span>
            );
        item["action"] = (
            <>
                <ButtonRole
                    role_url={`${props.location.pathname}/edit/:id`}
                    role_type="role_update"
                >
                    <NavLink
                        to={`${props.location.pathname}/edit/${item.user_id}`}
                        className="btn btn-sm btn-default"
                    >
                        <i className="fa fa-edit mr-1"></i> Update User
                    </NavLink>
                </ButtonRole>
            </>
        );
        return item;
    });
};

const GroupsTable = (props) => {
    return (
        <TableBootstrap
            keyField={"user"}
            empty="Belum ada user, klik tombol + untuk menambahkan user baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

export default GroupsTable;
