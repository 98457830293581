import React from "react";

// redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// komponen
import {
    TableBootstrap,
    Button,
    ButtonEdit,
    ButtonDelete,
    ButtonRole,
    ButtonGroup
} from "../../../components/AdminLTE";

// kolom
const columns = [
    {
        name: "No",
        selector: "no",
        sortable: true,
        width: "10%",
        center: true
    },
    {
        name: "Nama Unit",
        selector: "unit_name",
        sortable: true,
        width: "30%"
    },
    {
        name: "Kode Unit",
        selector: "code_name",
        width: "35%"
    },
    {
        name: "",
        selector: "action",
        width: "25%",
        center: true
    }
];

const data = props => {
    return props.list.map((item, index) => {
        item["no"] = index + 1;
        item["action"] = (
            <ButtonGroup>
                <ButtonRole
                    role_url={props.location.pathname}
                    role_type="role_update"
                >
                    <Button
                        onClick={() => props.toogleShowUnit(item)}
                        className="btn btn-sm btn-default"
                    >
                        <i className="fa fa-bars"></i> Kode Unit
                    </Button>
                </ButtonRole>
                <ButtonEdit onClick={() => props.toogleShow("edit", item)} />
                <ButtonDelete onClick={() => props.deleteConfirm(item)} />
            </ButtonGroup>
        );
        return item;
    });
};

const UnitTable = props => {
    return (
        <TableBootstrap
            keyField={"ref_sarpras"}
            empty="Belum ada referensi unit, klik tombol + untuk menambahkan referensi baru"
            columns={columns}
            data={data(props)}
            loading={props.loading}
        />
    );
};

//-- redux config
// state -> props
function mapsStateToProps(state) {
    return {
        menu: state.menu
    };
}
// hubungkan dengan redux
export default withRouter(connect(mapsStateToProps, null)(UnitTable));
