import React, { Component } from "react";
import classNames from "classnames";

// komponen
import { Toast } from "../../components/Alert";
import { Button, InputFeedback, LoadingBig } from "../../components/AdminLTE";

// utils
import Session from "../../utils/Session";
import GetPrivate, { PostPublic } from "../../utils/Connection";

// resources
import logo_jmto from "../../resources/img/logo-jmto.png";

class Login extends Component {
    constructor(props) {
        super(props);
        // set title
        document.title = "JMTO | Login User";
        //state
        this.state = {
            username: "",
            password: "",
            loading_init: true,
            loading: false,
            error_field: {
                username: "",
                password: ""
            }
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        // check session
        this.checkSession();
    }
    // check token
    checkSession() {
        this.setState({ loading_init: true }, function() {
            // panggil get api
            GetPrivate(this, "user/profile")
                .then(() => {
                    let default_page = Session.getSession(
                        "default_page__jmto_admin"
                    );
                    default_page = default_page ? default_page : "dashboard";
                    // redirect dashboard
                    this.props.history.push(default_page);
                })
                .catch(error => {
                    this.setState({ loading_init: false });
                });
        });
    }
    // handle change
    handleChange(field, value) {
        this.setState({ [field]: value });
    }
    //handleSubmit
    handleSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                username: that.state.username,
                password: that.state.password
            };
            // paggil post api
            PostPublic(that, "login", params)
                .then(result => {
                    // - set session
                    Session.setSession(
                        "session__jmto_admin",
                        result.data["Authorization"]
                    );
                    Session.setSession(
                        "default_page__jmto_admin",
                        result.data["default_page"]
                    );
                    // - redirect
                    that.props.history.push(result.data.default_page);
                    // notif
                    Toast("Login Berhasil");
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    // render
    render() {
        if (this.state.loading_init) return <LoadingBig />;
        else
            return (
              <div className="login-page">
                <div className="login-box">
                  <div className="login-logo">
                    {/* <img src={logo_jmto} /> */}
                    <h3>TOL Management System</h3>
                  </div>
                  <div className="login-box-body">
                    <p className="login-box-msg">
                      Aplikasi Monitoring Petugas MCS
                    </p>
                    <form onSubmit={this.handleSubmit}>
                      <InputFeedback
                        has_error={this.state.error_field.username}
                        defaultValue={this.state.username}
                        onChange={(ev) =>
                          this.handleChange("username", ev.target.value)
                        }
                        icon={"glyphicon glyphicon-envelope"}
                        error_field={this.state.error_field.username}
                        placeholder="Username"
                        type="text"
                      />
                      <InputFeedback
                        has_error={this.state.error_field.password}
                        defaultValue={this.state.password}
                        onChange={(ev) =>
                          this.handleChange("password", ev.target.value)
                        }
                        icon={"glyphicon glyphicon-lock"}
                        error_field={this.state.error_field.password}
                        placeholder="Password"
                        type="password"
                      />
                      <div className="row">
                        <div className="col-xs-12 pb-20">
                          <Button
                            type="submit"
                            className="btn btn-primary btn-block btn-flat"
                            loading={this.state.loading}
                          >
                            LOGIN
                          </Button>
                        </div>
                      </div>
                    </form>

                    <Button
                      type="button"
                      className="btn btn-link btn-block btn-flat"
                      loading={this.state.loading}
                    >
                      Lupa Password?
                    </Button>
                  </div>
                </div>
              </div>
            );
    }
}

//export
export default Login;
