import React, { Component } from "react";
import {
    Modal,
    Button,
    Input,
    InputSelectReact
} from "../../../components/AdminLTE";
import GetPrivate, { PostPrivate } from "../../../utils/Connection";
import ShiftTable from "./table";
import { SwalSuccess, SwalConfirm } from "../../../components/Alert";

class ShiftModal extends Component {
    // konstruktor
    constructor(props) {
        super(props);
        // state
        this.state = {
            list_code: this.props.item.unit_id
                ? this.props.list_code[this.props.item.unit_id]
                : [],
            list_pegawai: [],
            ruas_id: this.props.item.ruas_id || "",
            unit_id: this.props.item.unit_id || "",
            code_id: this.props.item.code_id || "",
            code_name: this.props.item.code_name || "",
            shift_id: this.props.item.shift_id || "",
            execution_date: this.props.item.execution_date || "",
            pegawai: this.props.item.pegawai || [],
            error_field: {},
            // --
            loading: false
        };
        // bind
        this.handleChange = this.handleChange.bind(this);
        this.addRow = this.addRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
        this.removeRowDB = this.removeRowDB.bind(this);
        this.changeRow = this.changeRow.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    // load data
    loadData() {
        // panggil get api
        this.setState({ loading: true }, function() {
            const { unit_id, ruas_id, execution_date, shift_id } = this.state;
            GetPrivate(
                this,
                `pegawai/shift/pegawai?ruas_id=${ruas_id}&unit_id=${unit_id}&date=${execution_date}&shift_id=${shift_id}`
            )
                .then(result => {
                    // set state
                    this.setState({
                        loading: false,
                        list_pegawai: result.data.map(item => ({
                            value: item.pegawai_id,
                            label: item.pegawai_name
                        }))
                    });
                })
                .catch(error => {
                    this.setState({
                        loading: false
                    });
                });
        });
    }
    // handleChange
    handleChange(field, value) {
        this.setState(
            {
                [field]: value,
                list_code:
                    field == "unit_id"
                        ? this.props.list_code[value]
                        : this.state.list_code
            },
            function() {
                if (field == "unit_id" || field == "ruas_id") {
                    this.loadData();
                    // reset data
                    this.setState({ code_id: "", code_name: "" });
                }
            }
        );
    }
    //addRow
    addRow() {
        const { pegawai } = this.state;
        this.setState({
            pegawai: [
                ...pegawai,
                {
                    member_id: "",
                    pegawai_id: "",
                    pegawai_name: ""
                }
            ]
        });
    }
    //removeRow
    removeRow(index) {
        let { pegawai } = this.state;
        pegawai.splice(index, 1);
        this.setState({
            pegawai: pegawai
        });
    }
    // deleteprocess
    removeRowDB(item, index) {
        SwalConfirm(
            `Hapus Shift`,
            "Data yang sudah dihapus tidak dapat dikembalikan lagi. Lanjutkan proses hapus?",
            () =>
                this.setState({ loading: true }, function() {
                    GetPrivate(
                        this,
                        `pegawai/shift/delete_pegawai/${item.member_id}`
                    )
                        .then(result => {
                            // remove row
                            this.removeRow(index);
                        })
                        .catch(error => {})
                        .finally(() => {
                            this.setState({
                                loading: false
                            });
                        });
                })
        );
    }
    // row change
    changeRow(index, value, label) {
        const { pegawai } = this.state;
        pegawai[index]["pegawai_id"] = value;
        pegawai[index]["pegawai_name"] = label;
        // set state
        this.setState({ pegawai: pegawai });
    }
    // on submit
    onSubmit = evt => {
        evt.preventDefault();
        // this
        const that = this;
        const { item } = this.props;
        const {
            execution_date,
            unit_id,
            code_id,
            shift_id,
            ruas_id,
            pegawai
        } = this.state;
        // api request
        that.setState({ loading: true }, function() {
            //params
            const params = {
                execution_id: item.execution_id,
                execution_date: execution_date,
                unit_id: unit_id,
                code_id: code_id,
                shift_id: shift_id,
                ruas_id: ruas_id,
                pegawai: JSON.stringify(pegawai)
            };
            // paggil post api
            PostPrivate(that, `pegawai/shift/update`, params)
                .then(result => {
                    that.setState(
                        {
                            loading: false
                        },
                        function() {
                            // close modal
                            that.props.toogleShow();
                            // load data parent
                            that.props.loadData();
                            // pesan
                            SwalSuccess("Berhasil", result.message);
                        }
                    );
                })
                .catch(error => {
                    if (typeof error == "object") {
                        that.setState({
                            error_field: error
                        });
                    }
                    // hapus loading
                    that.setState({
                        loading: false
                    });
                });
        });
    };
    //render
    render() {
        // return
        return (
            <Modal
                form={true}
                title="Jadwal Shift"
                content={
                    <>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        htmlFor={"ruas_id"}
                                        className="control-label"
                                    >
                                        Ruas Tol
                                    </label>
                                    <InputSelectReact
                                        id={"ruas_id"}
                                        has_error={
                                            this.state.error_field.ruas_id
                                        }
                                        defaultValue={{
                                            value: this.state.ruas_id,
                                            label: this.props.item.ruas_name
                                        }}
                                        onChange={item => {
                                            this.handleChange(
                                                "ruas_id",
                                                item.value
                                            );
                                        }}
                                        error_field={
                                            this.state.error_field.ruas_id
                                        }
                                        options={this.props.list_ruas}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        htmlFor={"unit_id"}
                                        className="control-label"
                                    >
                                        Unit
                                    </label>
                                    <InputSelectReact
                                        id={"unit_id"}
                                        has_error={
                                            this.state.error_field.unit_id
                                        }
                                        defaultValue={{
                                            value: this.state.unit_id,
                                            label: this.props.item.unit_name
                                        }}
                                        onChange={item => {
                                            this.handleChange(
                                                "unit_id",
                                                item.value
                                            );
                                        }}
                                        error_field={
                                            this.state.error_field.unit_id
                                        }
                                        options={this.props.list_unit}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        htmlFor={"unit_id"}
                                        className="control-label"
                                    >
                                        Tanggal Shift
                                    </label>
                                    <Input
                                        id={"execution_date"}
                                        type="date"
                                        placeholder="Tanggal Shift"
                                        has_error={
                                            this.state.error_field
                                                .execution_date
                                        }
                                        defaultValue={this.state.execution_date}
                                        onChange={ev =>
                                            this.handleChange(
                                                "execution_date",
                                                ev.target.value
                                            )
                                        }
                                        error_field={
                                            this.state.error_field
                                                .execution_date
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        htmlFor={"code_id"}
                                        className="control-label"
                                    >
                                        Kode Unit
                                    </label>
                                    {!this.state.loading && (
                                        <InputSelectReact
                                            id={"code_id"}
                                            has_error={
                                                this.state.error_field.code_id
                                            }
                                            defaultValue={{
                                                value: this.state.code_id,
                                                label: this.state.code_name
                                            }}
                                            onChange={item => {
                                                this.handleChange(
                                                    "code_id",
                                                    item.value
                                                );
                                            }}
                                            error_field={
                                                this.state.error_field.code_id
                                            }
                                            options={this.state.list_code}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label
                                        htmlFor={"shift_id"}
                                        className="control-label"
                                    >
                                        Shift
                                    </label>
                                    <InputSelectReact
                                        id={"shift_id"}
                                        has_error={
                                            this.state.error_field.shift_id
                                        }
                                        defaultValue={{
                                            value: this.state.shift_id,
                                            label: this.props.item.shift_name
                                        }}
                                        onChange={item => {
                                            this.handleChange(
                                                "shift_id",
                                                item.value
                                            );
                                        }}
                                        error_field={
                                            this.state.error_field.shift_id
                                        }
                                        options={this.props.list_shift}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="hr"></div>
                        <ShiftTable
                            list={this.state.pegawai}
                            addRow={this.addRow}
                            removeRow={this.removeRow}
                            removeRowDB={this.removeRowDB}
                            changeRow={this.changeRow}
                            loading={this.state.loading}
                            list_pegawai={this.state.list_pegawai}
                        />
                    </>
                }
                footer={
                    <Button
                        type="submit"
                        className="btn btn-primary"
                        loading={this.state.loading}
                    >
                        Simpan
                    </Button>
                }
                show={this.props.show}
                onDismiss={() => this.props.toogleShow({}, true)}
                onSubmit={this.onSubmit}
            />
        );
    }
}

export default ShiftModal;
